import "./Events.css";
import { AppBar, Button, Grid, InputBase, Typography, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
// eslint-disable-next-line no-unused-vars
import { WithErrorHandler, WithErrorHandlerProps } from "../../WithErrorHandler";
import { getAllEvents, searchEvents, setEventPublishStatus } from "../../../constant/api";
import Cards from "../../UI-Components/Card";
import CommonHeader from "../../UI-Components/CommonHeader";
import ConfirmationBox from "../../UI-Components/Confirmation";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import SearchIcon from "@material-ui/icons/Search";
import Welcome from "../Welcome/Welcome";
import history from "../../../history";

import noActivities from "../../../assets/Images/Icons/no-activities.svg";

interface Props extends WithErrorHandlerProps {
  readonly tabValue?: number;
  readonly getAllEvents?: () => Promise<void>;
  readonly searchEvents?: () => Promise<void>;
  readonly classes: {
    readonly newBtn: string;
  };
  readonly notify: (arg1: string, arg2: string) => void;
}

interface State {
  events: EventList[];
  searchText: string;
  tabValue: number;
  isSearch: boolean;
  isEventsFound: boolean;
  pageCount: number;
  totalPages: number;
  open: boolean;
  selectedEvent: EventList;
}
interface body {
  scrollTop: number;
  offsetHeight: number;
}

class Events extends PureComponent<Props, State> {
  isLoading: boolean;
  constructor(props: Props) {
    super(props);
    this.isLoading = true;
    this.state = {
      events: [],
      isEventsFound: false,
      isSearch: false,
      open: false,
      pageCount: 1,
      searchText: "",
      selectedEvent: {} as any,
      tabValue: 1,
      totalPages: 0,
    };
    this.getEvents(1, 1, 0);
  }

  registerScroll = () => {
    const card_container = document.getElementById("card-container");
    const { tabValue: params, pageCount, totalPages, searchText } = this.state;
    if (card_container !== null) {
      card_container.onscroll = () => {
        if (card_container.scrollTop > card_container.scrollHeight - card_container.offsetTop - 600) {
          if (!this.isLoading && pageCount < totalPages && searchText.length === 0) {
            if (params === 2) {
              this.getEvents(0, pageCount + 1, 1);
            } else {
              this.getEvents(params, pageCount + 1, 0);
            }
          }
          if (!this.isLoading && searchText.length >= 1 && pageCount < totalPages) {
            if (params === 2) {
              this.searchAllEvents(searchText, 0, pageCount + 1, 1);
            } else {
              this.searchAllEvents(searchText, params, pageCount + 1, 0);
            }
          }
        }
      };
    }
  };

  handleClick = () => {
    history.push("/create-event");
  };

  getEvents = async (params: number, page: number | undefined, isDraft: number | undefined) => {
    try {
      this.isLoading = true;
      const response = await getAllEvents(params, page, isDraft);
      let Events: EventList[] = [];
      if (page === 1) {
        Events = response.data.item;
      } else {
        const { events } = this.state;
        Events = events.concat(response.data.item);
      }
      this.isLoading = false;
      if (response.data.item.length === 0) {
        this.setState({
          events: Events,
          isEventsFound: true,
        });
      } else {
        this.setState({
          events: Events,
          isSearch: false,
          pageCount: page!,
          searchText: "",
          totalPages: response.data.metadata.totalPages,
        });
      }
      this.registerScroll();
    } catch (error: unknown) {
      const { errorHandler } = this.props;
      errorHandler(error);
    }
  };

  onTabClick = (tabValue: number) => {
    this.setState({
      events: [],
      isEventsFound: false,
      tabValue,
    });
    // check if draft selected
    if (tabValue === 2) {
      this.getEvents(0, 1, 1);
    } else {
      this.getEvents(tabValue, 1, 0);
    }
  };

  searchEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    const { tabValue: params, searchText: oldSearchTerm } = this.state;
    this.setState({
      searchText: e.target.value,
    });
    if (e.target.value.length >= 1) {
      if (params === 2) {
        this.searchAllEvents(e.target.value, 0, 1, 1);
      } else {
        this.searchAllEvents(e.target.value, params, 1, 0);
      }
    } else if (searchTerm.length < oldSearchTerm.length && searchTerm.length <= 2) {
      if (params === 2) {
        this.getEvents(0, 1, 1);
      } else {
        this.getEvents(params, 1, 0);
      }
    }
  };
  searchAllEvents = async (searchTerm: string, params: number, page: number, isDraft: number) => {
    try {
      this.isLoading = true;
      const response = await searchEvents(searchTerm, params, page, isDraft);
      // eslint-disable-next-line no-unused-vars
      let Events: EventList[] = [];
      if (page === 1) {
        Events = response.data.item;
      } else {
        const { events } = this.state;
        Events = events.concat(response.data.item);
      }

      this.isLoading = false;
      if (response.data.item.length === 0) {
        this.setState({
          events: response.data.item,
          isSearch: true,
        });
      } else {
        this.setState({
          events: Events,
          pageCount: page,
          totalPages: response.data.metadata.totalPages,
        });
      }
      this.registerScroll();
    } catch (error: unknown) {
      const { errorHandler } = this.props;
      errorHandler(error);
    }
  };

  checkEventsData = () => {
    const { isEventsFound, isSearch } = this.state;
    if (isEventsFound) {
      return (
        <Grid container>
          <Welcome
            imagePath={noActivities}
            header="No activities"
            text="Create activities for your customers to check into"
            path="/create-event"
            buttonText="Create Activity"
            testId="activities"
          />
        </Grid>
      );
    } else if (isSearch) {
      // TODO: provide appropriate message for no results found
      return (
        <Grid container className="noDataGrid">
          <Grid item xs={12}>
            <h5 className="align_center" data-testid="noDataAvailable">
              No activity results found
            </h5>
          </Grid>
        </Grid>
      );
    }

    return <LoadingScreen key={1} minHeight="calc(100vh - 250px)" />;
  };
  confirm = (obj: any) => {
    this.setState({
      open: true,
      selectedEvent: obj,
    });
  };
  closeConfirmation = () => {
    this.setState({
      open: false,
    });
  };
  goToPreviousPage = async () => {
    const { selectedEvent } = this.state;
    const { notify } = this.props;

    const newPublishStatus = !selectedEvent.publishStatus;

    try {
      await setEventPublishStatus(selectedEvent.eventId, newPublishStatus);

      history.push("/events");

      if (newPublishStatus) {
        notify(`${selectedEvent.name} published.`, "success");
      } else {
        notify(`${selectedEvent.name} unpublished.`, "success");
      }
    } catch (error: any) {
      notify(error.message, "error");
    }
  };

  handleOptions(obj: any) {
    const secondaryOptions = ["Edit", "Duplicate", "View Check-ins", "Publish"];
    const tertiaryOptions = ["Edit", "Duplicate"];
    const primaryOptions = ["Edit", "Duplicate", "View Check-ins", "Unpublish"];

    if (obj.publishStatus) {
      return primaryOptions;
    } else if (obj.isDraft) {
      return tertiaryOptions;
    }

    return secondaryOptions;
  }

  render() {
    const { events, tabValue, searchText, isEventsFound, open } = this.state;
    let eventsList = [<LoadingScreen key={1} minHeight="calc(100vh - 250px)" />];

    if (events && events.length > 0) {
      eventsList = events.map((obj) => {
        const objVal: any = obj;
        objVal.title = "Create Activity";
        const id = events.indexOf(objVal);

        return (
          <Grid key={id} item xs={6} sm={4} className="event-card-container" data-testid="eventCardContainer">
            <Cards
              id={objVal.name}
              name={objVal.name}
              options={this.handleOptions(objVal)}
              dataObj={objVal}
              pathName="/create-event"
              unpublish={this.confirm}
              testId={objVal.name}
            />
          </Grid>
        );
      });
    }

    return (
      <div>
        <AppBar position="static" className="appbar app_bar cont-scrl">
          <CommonHeader
            name="Activities"
            buttonText="Create activity"
            handleClick={this.handleClick}
            text="Activities are what you offer to your participants. You can also add policies requirements."
            testId="activities"
          />
        </AppBar>
        <div>
          {/* //align="left */}
          {/* <Grid
  container
  direction="row"
  justify="center"
  alignItems="flex-start"

> */}
          <Grid container spacing={0} alignItems="flex-start" justify="center" className="filters">
            <Grid item xs={5}>
              <Button
                className={tabValue === 1 ? "custom-button-1 active" : "custom-button-1"}
                onClick={this.onTabClick.bind(this, 1)}
              >
                <Typography variant="subtitle1" data-testid="publishedButton">
                  Published
                </Typography>
              </Button>

              <Button
                className={tabValue === 2 ? "custom-button-1 active" : "custom-button-1"}
                onClick={this.onTabClick.bind(this, 2)}
              >
                <Typography variant="subtitle1" data-testid="draftButton">
                  Draft
                </Typography>
              </Button>

              <Button
                className={tabValue === 0 ? "custom-button-1 active" : "custom-button-1"}
                onClick={this.onTabClick.bind(this, 0)}
              >
                <Typography variant="subtitle1" data-testid="unPublishedButton">
                  Unpublished
                </Typography>
              </Button>
            </Grid>
            <Grid xs={7}>
              <div
                style={{
                  background: "#CFE4FD",
                  borderRadius: "24px",
                  display: "inline-block",
                  position: "relative",
                }}
              >
                <SearchIcon
                  style={{ height: 20, left: 0, paddingLeft: "10px", position: "absolute", top: "11px", width: 20 }}
                />
                <InputBase
                  placeholder=" Search"
                  value={searchText}
                  id="search3"
                  onChange={this.searchEvent}
                  style={{
                    height: "40px",
                    paddingLeft: "30px",
                    width: "184px",
                  }}
                  disabled={isEventsFound}
                  inputProps={{
                    "data-testid": "searchInputField",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {events.length === 0 ? (
          this.checkEventsData()
        ) : (
          <div className="card_container" id="card-container">
            <Grid container spacing={24} className="grid_container" data-testid="gridContainer">
              {eventsList}
            </Grid>
          </div>
        )}
        <ConfirmationBox
          open={open}
          closeConfirmation={this.closeConfirmation}
          goToPreviousPage={this.goToPreviousPage}
          message="Do you want to publish/unpublish this activity?"
          testId="publish-unPublishConfirmationModal"
        />
      </div>
    );
  }
}

const styles = () =>
  createStyles({
    newBtn: {
      backgroundColor: "#fff",
      border: "1px solid #147DF4",
      height: 39,
      position: "absolute",
      right: "175px",
    },
  });

export default withStyles(styles)(WithErrorHandler(Events));
