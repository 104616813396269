// eslint-disable-next-line no-unused-vars
import { Kiosk as ActiveOrInactiveKioskType, KioskType } from "../../../types/kiosk";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { ActiveInactiveTabs } from "./ActiveInactiveTabs";
import { Colors } from "../../../theme/Colors";
import CommonHeader from "../../UI-Components/CommonHeader";
import { EmptyKioskTab } from "./EmptyKioskTab";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import { getKiosks } from "../../../constant/api";

import history from "../../../history";

import { useDefaultErrorHandler } from "../../../utils/useDefaultErrorHandler";

interface Props extends RouteComponentProps<{}, {}, { isVirtualKioskTabActive: boolean }> {
  readonly notify: (arg1: string, arg2: string) => void;
}

const virtualKioskPageCount = 1;
const eventKioskPageCount = 1;

export const Kiosk = ({ location, notify }: Props) => {
  const activeTabIndex = location.state?.isVirtualKioskTabActive ? 2 : 0;

  const [activeTab, setActiveTab] = useState(activeTabIndex);
  const [loading, setLoading] = useState(true);

  // Virtual kiosk
  const [activeVirtualKiosk, setActiveVirtualKiosk] = useState<ActiveOrInactiveKioskType[]>([]);
  const [inactiveVirtualKiosk, setInactiveVirtualKiosk] = useState<ActiveOrInactiveKioskType[]>([]);
  const [totalVirtualKioskPages, setTotalVirtualKioskPages] = useState<number>(0);

  // Event kiosk
  const [activeEventKiosk, setActiveEventKiosk] = useState<ActiveOrInactiveKioskType[]>([]);
  const [inactiveEventKiosk, setInactiveEventKiosk] = useState<ActiveOrInactiveKioskType[]>([]);
  const [totalEventKioskPages, setTotalEventKioskPages] = useState<number>(0);

  const { errorHandler } = useDefaultErrorHandler();
  const handleClick = () => {
    history.push("/kioskname", { kioskType: activeTab === 0 ? KioskType.EVENT : KioskType.VIRTUAL });
  };

  const getKioskEvents = async () => {
    try {
      const activeVirtualKioskEvents = await getKiosks(1, virtualKioskPageCount, KioskType.VIRTUAL);
      if (activeVirtualKioskEvents.data.item.length > 0) {
        setActiveVirtualKiosk(activeVirtualKioskEvents.data.item);
        setTotalVirtualKioskPages(activeVirtualKioskEvents.data.metadata.totalPages);
      }

      const inActiveVirtualKioskEvents = await getKiosks(0, virtualKioskPageCount, KioskType.VIRTUAL);
      if (inActiveVirtualKioskEvents.data.item.length > 0) {
        setInactiveVirtualKiosk(inActiveVirtualKioskEvents.data.item);
      }

      const activeEventKioskEvents = await getKiosks(1, eventKioskPageCount, KioskType.EVENT);
      if (activeEventKioskEvents.data.item.length > 0) {
        setActiveEventKiosk(activeEventKioskEvents.data.item);
        setTotalEventKioskPages(activeEventKioskEvents.data.metadata.totalPages);
      }
      const inActiveKioskEvents = await getKiosks(0, eventKioskPageCount, KioskType.EVENT);
      if (inActiveKioskEvents.data.item.length > 0) {
        setInactiveEventKiosk(inActiveKioskEvents.data.item);
      }
      setLoading(false);
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getKioskEvents();
  }, []);

  const handleVirtualKioskView = () => {
    if ((activeVirtualKiosk.length > 0 || inactiveVirtualKiosk.length > 0) && !loading) {
      return (
        <ActiveInactiveTabs
          notify={notify}
          activeKiosk={activeVirtualKiosk}
          pageCount={virtualKioskPageCount}
          totalPages={totalVirtualKioskPages}
          kioskType={KioskType.VIRTUAL}
        />
      );
    }

    return <EmptyKioskTab kioskType={KioskType.VIRTUAL} />;
  };

  const handleEventKioskView = () => {
    if ((activeEventKiosk.length > 0 || inactiveEventKiosk.length > 0) && !loading) {
      return (
        <ActiveInactiveTabs
          notify={notify}
          activeKiosk={activeEventKiosk}
          pageCount={eventKioskPageCount}
          totalPages={totalEventKioskPages}
          kioskType={KioskType.EVENT}
        />
      );
    }

    return <EmptyKioskTab kioskType={KioskType.EVENT} />;
  };

  return (
    <div className="tabs_container">
      <AppBar position="static" className="appbar app_bar">
        <CommonHeader
          name="Kiosk Methods"
          buttonText={activeTab === 0 ? "Create Event" : "Create Virtual Kiosk"}
          testId={activeTab === 0 ? "createEvent" : "createVirtualKiosk"}
          handleClick={handleClick}
          text={
            activeTab === 0
              ? "Saving Activities to an Event allows you to manage them with more ease on physical kiosks."
              : "The accompanying kiosk app lets your participants easily check themselves in to the activity you created."
          }
        />
      </AppBar>
      <Tabs
        centered
        value={activeTab}
        onChange={(_: any, activeTab: number) => {
          setActiveTab(activeTab);
        }}
        indicatorColor="primary"
        textColor="primary"
        className={css(styles.tabs)}
      >
        <Tab data-testid="eventTab-Kiosk" label="Event" />
        <div className={css(styles.verticalLine)} />
        <Tab data-testid="virtualTab-Kiosk" label="Virtual" />
      </Tabs>
      {loading && <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />}

      {activeTab === 0 && <Grid style={{ marginTop: 20 }}>{handleEventKioskView()}</Grid>}
      {activeTab === 2 && <Grid style={{ marginTop: 20 }}>{handleVirtualKioskView()}</Grid>}
    </div>
  );
};

const styles = StyleSheet.create({
  tabs: { backgroundColor: Colors.white, textAlign: "center", width: "100%" },
  verticalLine: {
    borderRight: `1px solid grey`,
    height: 20,
    marginTop: 12,
  },
});

export default withRouter(Kiosk);
