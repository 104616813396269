import {
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { memo, useEffect, useMemo, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { None } from "../../../../utils/None";
import { ShowEventAttendeeDetails } from "../ShowEventAttendeeDetails";
import { Some } from "../../../../utils/Some";
import { editCheckinCheckoutTime } from "../../../../constant/api";
import moment from "moment-timezone";

interface Props {
  readonly show: boolean;
  readonly notify: (message: string, status: string) => void;
  readonly handleClose: () => void;
  readonly classes: {
    readonly paper: string;
    readonly button: string;
    readonly info: string;
    readonly root: string;
    readonly table: string;
    readonly backdrop: string;
  };
  readonly eventAttendee: number[];
  readonly clearEventAttendeeIds: () => void;

  selectedAttendees: EventAttendee[];
  checkInTz: string | null;
  checkOutTz: string | null;
  checkInTime: string;
  checkOutTime: string;
}

const ERROR_MESSAGES = {
  checkInAfterCheckOut: "The Date and Time should be greater than the check in date.",
  futureDate: "The Date and Time should be less than the current date.",
  invalidDate: "Please select valid date.",
};

const EDIT_CHECKOUT_DISABLE_MESSAGE = "Check-out is not enabled for any of the selected attendees";

const EditCheckInCheckOutTimeModal = memo(
  ({
    show,
    handleClose,
    eventAttendee,
    notify,
    clearEventAttendeeIds,
    classes,
    selectedAttendees,
    checkOutTz,
    checkInTz,
    checkInTime: initialCheckInTime,
    checkOutTime: initialCheckOutTime,
  }: Props) => {
    const [checkInTime, setCheckInTime] = useState<string>();
    const [checkOutTime, setCheckOutTime] = useState<string>();
    const [editCheckins, setEditCheckins] = useState<boolean>();
    const [editCheckouts, setEditCheckouts] = useState<boolean>();
    const [checkinError, setCheckinError] = useState<string>("");
    const [checkoutError, setCheckoutError] = useState<string>("");
    const [failedAttendees, setFailedAttendees] = useState<EventAttendee[]>([]);
    const [failedAttendeesError, setFailedAttendeesError] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);

    const enableEditCheckout = useMemo(() => {
      return selectedAttendees.some((item) => item.event.isCheckOutReq || Some(item.checkOut));
    }, [selectedAttendees]);

    useEffect(() => {
      if (eventAttendee.length === 1) {
        setCheckInTime(initialCheckInTime);
        if (enableEditCheckout) {
          setCheckOutTime(initialCheckOutTime);
        }
      }
    }, [eventAttendee, enableEditCheckout]);

    const saveCheckInCheckOutTime = async () => {
      if (checkinError.length > 0 || checkoutError.length > 0) {
        return;
      }
      setSubmitted(true);
      const eventAttendeeIds =
        failedAttendees.length > 0
          ? failedAttendees.map(({ eventAttendeeId }: EventAttendee) => eventAttendeeId)
          : eventAttendee;
      try {
        const params = {
          canEditCheckins: editCheckins,
          canEditCheckouts: editCheckouts,
          checkIn: Some(checkInTz) ? moment.tz(checkInTime, checkInTz).utc().format() : checkInTime,
          checkOut: Some(checkOutTz) ? moment.tz(checkOutTime, checkOutTz).utc().format() : checkOutTime,
          eventAttendeeIds,
        };
        const response = await editCheckinCheckoutTime(params);
        const failedAttendees = response.data
          .filter(({ status }) => status === "failed")
          .map(({ eventAttendeeId, message }) => {
            const failedEventAttendee = selectedAttendees.find((e) => e.eventAttendeeId === eventAttendeeId);
            if (Some(failedEventAttendee)) {
              return {
                ...failedEventAttendee,
                notes: message,
              };
            }

            return undefined;
          })
          .filter(Some);

        if (failedAttendees.length === 0) {
          notify("Edit checkin/checkout times successful", "success");
          handleClose();
          clearEventAttendeeIds();
        } else {
          setFailedAttendees(failedAttendees);
          setFailedAttendeesError("Failed to edit checkin/checkout times for below persons");
        }
      } catch (err) {
        notify(err.message || "Edit checkin/checkout times failed", "error");
        handleClose();
        clearEventAttendeeIds();

        return;
      } finally {
        setSubmitted(false);
      }
    };

    const isFutureDate = (tz: string | null, value: string) =>
      Some(tz) ? moment.tz(value, tz).isAfter(moment.tz(new Date(), tz)) : new Date(value) > new Date();

    // Managing time zones for a single event attendee check-in
    const handleCheckinTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (!value) {
        setCheckInTime(value);
        setCheckinError(ERROR_MESSAGES.invalidDate);

        return;
      }

      if (isFutureDate(checkInTz, value)) {
        setCheckInTime(value);
        setCheckinError(ERROR_MESSAGES.futureDate);

        return;
      }

      setCheckInTime(value);
      setCheckinError("");
    };

    useEffect(() => {
      if (Some(checkInTime) && checkInTime.length > 0 && Some(checkOutTime) && checkOutTime.length > 0) {
        handleCheckoutTimeChange(checkOutTime);
      }
    }, [checkInTime]);

    /**
     * Managing time zones for a single event attendee check-out
     * When we select or edit just one event attendee, this modal will receive the check-out timezone
     */
    const handleCheckoutTimeChange = (value: string) => {
      if (!value) {
        setCheckOutTime(value);
        setCheckoutError("Please select valid checkout date.");

        return;
      }

      if (isFutureDate(checkOutTz, value)) {
        setCheckOutTime(value);
        setCheckoutError(ERROR_MESSAGES.futureDate);

        return;
      }

      if (Some(checkInTime)) {
        const checkInDate = Some(checkOutTz)
          ? moment.tz(checkInTime, checkInTz || selectedAttendees[0].checkInTz)
          : new Date(checkInTime);

        if (checkInDate > ((Some(checkOutTz) && moment.tz(value, checkOutTz)) || new Date(value))) {
          setCheckOutTime(value);
          setCheckoutError(ERROR_MESSAGES.checkInAfterCheckOut);

          return;
        }
      }

      setCheckOutTime(value);
      setCheckoutError("");
    };

    const eventAttendees = failedAttendees.length > 0 ? failedAttendees : selectedAttendees;

    const disableSave = useMemo(() => {
      return (
        checkinError.length > 0 ||
        checkoutError.length > 0 ||
        submitted ||
        (editCheckins && None(checkInTime)) ||
        (editCheckouts && None(checkOutTime)) ||
        (!editCheckins && !editCheckouts)
      );
    }, [checkinError, checkoutError, submitted, checkInTime, checkOutTime, editCheckins, editCheckouts]);

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={show}
        disableBackdropClick
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.paper}>
          <Grid container>
            <Grid item>
              <CloseIcon onClick={handleClose} className="close_icon" />
            </Grid>
            <Grid item>
              <h4 className="modal-title" data-testid="header-EditCheckInCheckOutTimeModal">
                Edit Record Times
              </h4>
              <h6 className="modal_text" data-testid="checkInText-EditCheckInCheckOutTimeModal">
                Check-In the selected people
              </h6>
            </Grid>
            <Grid item className="image_padding">
              <TextField
                id="datetime-local"
                data-testid="checkInTimeInputField-EditCheckInCheckOutTimeModal"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={checkInTime}
                onChange={handleCheckinTimeChange}
              />
            </Grid>
            <span className="error-msg highlight">{checkinError}</span>
            <Grid item style={{ marginTop: 25 }}>
              <h6 className="modal_text" data-testid="checkOutText-EditCheckInCheckOutTimeModal">
                Check-out the selected people
              </h6>
            </Grid>
            <Grid item className="image_padding">
              <Tooltip title={enableEditCheckout ? "" : EDIT_CHECKOUT_DISABLE_MESSAGE}>
                <TextField
                  id="datetime-local"
                  data-testid="checkOutTimeInputField-EditCheckInCheckOutTimeModal"
                  type="datetime-local"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={checkOutTime}
                  disabled={!enableEditCheckout}
                  placeholder=""
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckoutTimeChange(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <span className="error-msg highlight">{checkoutError}</span>
            <Grid container spacing={24} style={{ marginTop: 2 }}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Switch
                      data-testid="editCheckInsToggle-EditCheckInCheckOutTimeModal"
                      onChange={(_, checked) => {
                        setEditCheckins(checked);
                      }}
                      name="edit-check-ins"
                      color="primary"
                      checked={editCheckins}
                    />
                  }
                  label="Edit Check-ins"
                />
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={enableEditCheckout ? "" : EDIT_CHECKOUT_DISABLE_MESSAGE}>
                  <FormControlLabel
                    control={
                      <Switch
                        data-testid="editCheckOutsToggle-EditCheckInCheckOutTimeModal"
                        onChange={(_, checked) => {
                          setEditCheckouts(checked);
                        }}
                        name="edit-check-outs"
                        color="primary"
                        disabled={!enableEditCheckout}
                      />
                    }
                    label="Edit Check-outs"
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item className={classes.info} style={{ marginTop: 25 }}>
              <h6 data-testid="activityTimezoneText-EditCheckInCheckOutTimeModal">
                <b>Note:</b> The check-in/check-out time should be in the activity&#39;s timezone.
              </h6>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className="common_buttons"
                style={{ float: "right", marginTop: "15px" }}
                onClick={saveCheckInCheckOutTime}
                disabled={disableSave}
                data-testid="saveButton-EditCheckInCheckOutTimeModal"
              >
                Save
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 15 }}>
              <span className="error-msg highlight">{failedAttendeesError}</span>
            </Grid>
          </Grid>
          {eventAttendees.length !== 0 && (
            <Paper className={classes.root} style={{ overflowX: "auto" }}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className="record_data">Full Name</TableCell>
                    <TableCell className="record_data">Email</TableCell>
                    {failedAttendees.length > 0 && <TableCell className="record_data">Status</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventAttendees.map(({ eventAttendeeId, firstName, lastName, email, notes }: EventAttendee) => (
                    <ShowEventAttendeeDetails
                      key={eventAttendeeId.toString()}
                      eventAttendeeId={eventAttendeeId}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      status={failedAttendees.length > 0 && notes ? notes : undefined}
                    />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </div>
      </Modal>
    );
  },
);

const styles = (theme: { spacing: { unit: number }; palette: { background: { paper: any } }; shadows: any[] }) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    info: {
      marginTop: 20,
      paddingLeft: 7,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      left: "50%",
      maxHeight: "calc(100vh - 20px)",
      "overflow-y": "auto",
      padding: theme.spacing.unit * 2,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 55,
    },
    root: {
      height: "200px",
      marginTop: theme.spacing.unit * 3,
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
    table: {
      minWidth: 350,
    },
  });

export default withStyles(styles)(EditCheckInCheckOutTimeModal);
